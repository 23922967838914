<script setup>
import { computed } from 'vue';
const props = defineProps(['message']);

const formatedMessage = computed(() => {
    if (typeof props.message === "object") {
        return Object.values(props.message).join(", ");
    } else {
        return props.message;
    }
});
</script>

<template>
    <div v-show="message">
        <p class="text-sm text-red-700 dark:text-red-500 font-medium leading-6">
            {{ formatedMessage }}
        </p>
    </div>
</template>
